:root {
  --blue-bg-color: #0572ec;
  --blue-hover-color: #0458b9;
}

/* General button style (reset) */
.btn {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 1.25em 2em;
  display: inline-block;
  margin: 0.5em 2em;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s;
}

/* Pseudo elements for icons */
.btn:before {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

/* Button 5 */
.btn-5 {
  background: var(--blue-bg-color);
  color: #fff;
  height: auto;
  min-width: 260px;
  line-height: 1rem;
  font-size: 16px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 100px;
}

.btn-5:hover {
  background: var(--blue-hover-color);
}

.btn-5:active {
  top: 2px;
}

.btn-5 span {
  display: inline-block;
  width: 100%;
  height: auto;
  -webkit-transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  -moz-transition: all 0.3s;
  -moz-backface-visibility: hidden;
  transition: all 0.3s;
  backface-visibility: hidden;
}
.bumpText {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
}

.btn-5b-before {
  left: -100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-5b-active {
  left: 0;
  background-color: #27ae60;
}

.fa-icon-check {
  position: relative;
}
