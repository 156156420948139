/* Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul[role="list"],
ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* End Reset */

:root {
  --blue-bg-color: #0572ec;
  --blue-hover-color: #0458b9;
  --bg-color: #0a2d4d;
  --cb-size: 25px;
  --cb-rotation-deg: 40deg;
}

body {
  background-color: var(--bg-color);
  color: #fff;
  margin: 0;
  font-size: 1rem;
}

.main-title {
  text-align: center;
  font-size: 2.25rem;
  font-weight: bold;
}

.container {
  width: 85%;
  max-width: 640px;
  height: 50vh;
  /* margin: 10vh auto; */
  margin: 20vh auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.row > div {
  margin-bottom: 1em;
}

.password-display {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #333;
  background: #fff;
  border-radius: 5px;
  padding: 0.5em;
  margin: 1em 0;
  font-size: 1.6rem;
  font-family: monospace;
  font-weight: bold;
  letter-spacing: 2px;
  word-wrap: break-word;
}

.password-display__length {
  width: 100%;
}

input[type="range"] {
  width: 100%;
}

.copy-password,
.copy-password2 {
  width: 200px;
  padding: 1em 2em;
  background-color: var(--blue-bg-color);
  color: white;
  border-radius: 100px;
  font-weight: bold;
  border: 0;
}

.copy-password:hover,
.copy-password:focus,
.copy-password2:hover,
.copy-password2:focus {
  background-color: #0458b9;
  transition: background-color 0.2s ease-in-out;
}

.copy-password2 {
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}
.copy-button-text {
  display: block;
}

.checkbox-wrapper {
  display: flex;
  justify-content: center;
}
.checkbox-container + .checkbox-container {
  margin-left: 1em;
}
.checkbox-container label {
  cursor: pointer;
  display: flex;
}
.checkbox-container input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkbox-container label::before {
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.05em white;
  background-color: white;
}

.checkbox-container label:hover::before,
.checkbox-container input[type="checkbox"]:hover + label::before {
  border: 0.05em var(--blue-bg-color);
}

.checkbox-container input[type="checkbox"]:checked + label::before {
  content: "\002714";
  background-color: var(--blue-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.button {
  display: block;
  background-color: var(--blue-bg-color);
  width: 200px;
  height: 50px;
  margin: auto;
  color: #fff;

  cursor: pointer;
  overflow: hidden;
  border-radius: 100px;
  border: none;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}
.button span,
.button .icon {
  display: block;
  height: 100%;
  text-align: center;
}
.button span {
  width: 100%;
  line-height: inherit;
  font-size: 1rem;
  left: 0;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.button .icon {
  width: 28%;
  right: 0;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}
.button .icon .fa {
  font-size: 30px;
  vertical-align: middle;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}
.button .icon .fa-remove {
  height: 36px;
}
.button .icon .fa-check {
  display: none;
}
.button.success span {
  left: -72%;
  opacity: 0;
}
.button.success .icon {
  width: 100%;
}
.button.success .icon .fa {
  font-size: 45px;
}
.button.success {
  background-color: #27ae60;
}
.button.success .icon .fa-remove {
  display: none;
}
.button.success .icon .fa-check {
  display: inline-block;
}
.button:hover {
  opacity: 0.9;
}
.button:hover .icon .fa-remove {
  height: 46px;
}
.button:active {
  opacity: 1;
}

.regeneratePasswordIcon {
  transition: transform 0.5s ease-in-out;
  margin: 0;
  font-size: 1.5em;
}
.regeneratePasswordIcon:hover {
  transform: rotate(180deg);
  cursor: pointer;
}

.letter {
  color: rgb(51, 51, 51);
}
.number {
  color: rgb(5, 114, 236);
}
.symbol {
  color: rgb(230, 74, 74);
}

/* Media queries */
@media (min-width: 670px) {
  .row {
    flex-direction: row;
  }
  .regeneratePasswordIcon {
    margin-left: 1em;
  }
  .checkbox-wrapper {
    justify-content: flex-start;
  }

  .container {
    margin: 10vh auto;
  }
}
